<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../helpers/errorHandler';
import FlipCountdown from 'vue2-flip-countdown';
import moment from "moment";
import userService from '../../../helpers/userService';

/**
 * Starter component
 */
export default {
  page: {
    title: "Quiz",
  },
  data() {
    return {
      title: "Quiz",
      items: [
        {
          text: "Work",
          href: "/home",
        },
        {
          text: "Quizzes",
          href: "/quizes-log",
        },
        {
          text: "Quiz",
          active: true,
        },
      ],
      form:{
        quiz_id: this.$route.params.quiz,
        answers:[],
      },
      questions:[],
      baseUrl:null,
      deadTime: null,
      type:null,
      submitted: false,
      loading: false
    };
  },
  components: {
    Layout,
    PageHeader,
    FlipCountdown,
  },
  async created(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    if(userService.methods.userRole() == 1){
      await this.$store.dispatch('quiz/getStudentQuizAnswers',this.$route.params.quiz).then( async (res)=>{
          this.questions = res;
          // this.type = res.quiz_meta_data.quiz_type;
      }).catch( err => {
          errorHandler.methods.errorMessage(err);
      });
    }else{
      await this.$store.dispatch('quiz/getStudentQuizAnswersAdmin',{quiz:this.$route.params.quiz,id:this.$route.params.student}).then( async (res)=>{
          this.questions = res;
          // this.type = res.quiz_meta_data.quiz_type;
      }).catch( err => {
          errorHandler.methods.errorMessage(err);
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Your answers</h2>
                    <hr>
                    <h2 class="text-danger" v-if="this.questions.length == 0">No answer to show</h2>
                  </div>
                <form action="#">
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                        id="input-group-2"
                        :label="`Question ${index+1}:`"
                        label-for="input-A"
                        v-for=" question,index in questions"
                        :key="question.quiz_question.id"
                    >
                        <!-- <img style="width: 300px; height: 300px;" v-if="question.quiz_question.question_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.quiz_question.question_img}`"> -->
                        <h3 v-html="question.quiz_question.question_text" class="question">
                        </h3>
                        <div class="row">
                            <div class="col-12" v-if="question.quiz_question.ans_one ">
                                <b-form-radio :checked="(question.answer == 'ans_one')?'ans_one':false" disabled :name="`some-radios-${index}`" value="ans_one"><div v-html="question.quiz_question.ans_one"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.quiz_question.ans_two ">
                                <b-form-radio :checked="(question.answer == 'ans_two')?'ans_two':false" disabled  :name="`some-radios-${index}`" value="ans_two"><div v-html="question.quiz_question.ans_two"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.quiz_question.ans_three ">
                                <b-form-radio :checked="(question.answer == 'ans_three')?'ans_three':false" disabled :name="`some-radios-${index}`" value="ans_three"><div v-html="question.quiz_question.ans_three"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.quiz_question.ans_four "> 
                                <b-form-radio :checked="(question.answer == 'ans_four')?'ans_four':false" disabled :name="`some-radios-${index}`" value="ans_four"><div v-html="question.quiz_question.ans_four"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.quiz_question.ans_five ">
                                <b-form-radio :checked="(question.answer == 'ans_five')?'ans_five':false" disabled :name="`some-radios-${index}`" value="ans_five"><div v-html="question.quiz_question.ans_five"></div></b-form-radio>
                            </div>
                        </div>
                        <div>
                          <h2 :class="(question.answer == question.quiz_question.right_ans)?'text-success':'text-danger'">{{ (question.answer == question.quiz_question.right_ans)? '✓ Correct Answer' : '✖ Wrong Answer' }}</h2>
                          <h4 v-if="(question.answer != question.quiz_question.right_ans)" class="text-success">✓ Correct Answer :
                            <span v-if="question.quiz_question.right_ans == 'ans_one'">Answer One</span>
                            <span v-if="question.quiz_question.right_ans == 'ans_two'">Answer Two</span>
                            <span v-if="question.quiz_question.right_ans == 'ans_three'">Answer Three</span>
                            <span v-if="question.quiz_question.right_ans == 'ans_four'">Answer Four</span>
                            <span v-if="question.quiz_question.right_ans == 'ans_five'">Answer Five</span>
                          </h4>
                        </div>
                        <hr>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <b-button variant="outline-success" class="ml-2 aline-right" @click="()=>{$router.go(-1);this.finish()}"
                >Done</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
.question::v-deep img {
  max-height: 320px !important;
  max-width: 320px !important;
}
</style>